import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import medischepeelings from '../images/medischepeelings.jpg';

const PeelingModal = ({ showModal, handleClose }) => {
  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Chemische peeling met led-therapie</Modal.Title>
      </Modal.Header>
      <Modal.Body id="modalbody">
      <p><b>Hoogontwikkeld gamma professionele chemische peelings</b></p>
      <p>Dankzij verschillende hydrozuren hebben deze peels een exfoliërende actie op de huid.</p>
      <p>De behandeling is eenvoudig en doeltreffend en veroorzaakt een gecontroleerde vernieuwing van de opperhuid en de diepere huidlaag. Deze behandeling verbetert niet alléén het uitzicht van de huid, maar stimuleert ook de huidvernieuwing en verjonging. De peelings zijn mild en veroorzaken een licht branderig of prikkerig gevoel.</p>
      <p>Aangewezen bij acne, huidverslapping, rimpels, littekens rokershuid, grauwe huid, grove porieen en huidimperfecties.</p>
      <p>Een peeling geeft u huid een prachtige frisse uitstraling. De peeling wordt aanbevolen in combinatie met LED-therapie voor een sneller helingsproces van de huid. </p>
      <p>LED-therapie  biedt een pijnloze oplossing voor een gezondere, jonger uitziende huid. Het volledige programma zal bijdragen tot het verminderen van fijne lijntjes, rimpels, verkleinen van de poriën, huidverstrakking,weefselgenezing, collageenstimulatie en ontgifting.</p>
      <img id="medischepeeling" class="modalpicture" align="middle" src={medischepeelings} alt="medischepeelings"/>
      <p><b>Chemische peeling volgens huidtype</b></p>
      <p>Inclusief reiniging en masker.</p>
      <p class="price">95,00 EUR</p>
                    
      <p>Behandeling aan te raden in kuurvorm voor een optimaal resultaat.</p>
      <p>Kom vrijblijvend langs, ik adviseer u graag welke peeling het beste bij uw huid past.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PeelingModal;