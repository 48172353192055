import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const PedicureModal = ({ showModal, handleClose }) => {
  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Pedicure</Modal.Title>
      </Modal.Header>
      <Modal.Body id="modalbody">
        <p>Een goede voetverzorging hoort bij een essentiële basishygiëne.</p>
        <p> Met een regelmatige pedicure ( elke 4 tot 6 weken ) kan je probleemloos vertrouwen op je belangrijkste onderdanen.</p>
        <p>Intensieve verzorging van de voeten</p>
        <p class="price">Prijs: 32,00 EUR</p>
        <p>Supplement lakken, inclusief base coat en top coat</p>
        <p class="price">Prijs: 10,00 EUR</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PedicureModal;